#root{
    position: absolute;
}
.full-page-container{
    height: 100vh;
    width: 100%;
    position: absolute;
}

.centering-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.scroll-snap-container{
    scroll-snap-type: y mandatory;
}

.scroll-snap-element{
    scroll-snap-align: start;
}

.header-section{
    top: 0;
    width:100%;
    height:10vh;
    background-color: #C6C5B9;
    display: flex;
    align-items: center;
    position: fixed;
    padding: 10px;
    z-index: 100;

}

.content-section{
    padding-top: 5px;
    margin-top: 15vh;
    height: 85vh;
}

.back-icon{
    font-size: 2rem;
}


/* cursor stuff */
.pointer-cursor-on-hover:hover{
    cursor: pointer;

}
