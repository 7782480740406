@tailwind base;
@tailwind components;
@tailwind utilities;

.card-container {
  @apply grid grid-flow-row gap-4 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-auto-fit;
}

.h-1\/8 {
  height: 12.5%;
}
.h-7\/8 {
  height: 87.5%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
  width: 100%;
  --app-height: 100%;
  background-color: #cbd5e1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
}
